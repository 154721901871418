import React, { lazy } from 'react';
import './App.css';
import './style.scss';

import { 
  BrowserRouter as Router, 
  Route, 
  Switch 
} from 'react-router-dom';

import Loader from './components/common/Loader'


/* Lazy Loading Components */
const LandingPage = lazy(() => import('./pages/LandingPage'));



const loading = () => <Loader />;

function App() {
  return (
    <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/" name="Home Page" render={props => <LandingPage {...props}/>} />
          </Switch>
        </React.Suspense>
    </Router>
  );
}

export default App;
